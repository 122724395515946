import React, {useEffect, useState, useRef} from "react";
import { useLocation, useHistory } from "react-router-dom";
import "../../css/layout.css";
import "./style.css"
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import LinkIcon from "../../images/Link-icon.svg";

import CloseIcon from "../../images/Close-icon.svg";
import DownloadIcon from "../../images/Download-button.svg";
import api from "../../lib/API";
import DetailDialog from "./Dialog/DetailDialog";

export default function NoOfInvalidForms(){
    const location = useLocation();
    const props = location.state;
    const history = useHistory();

    const [dataSource, setDataSource] = useState(null);

    const dt = useRef(null);
    const [detailClicked, setDetailClicked] = useState(false);
    const [rowInfo, setRowInfo] = useState({});

    const [itpReferenceVisibilityButtonLabel, setItpReferenceVisibilityButtonLabel] = useState('Hide ITP Reference');
    const [itpReferenceVisibility, setItpReferenceVisibility] = useState(true);

    if(!props){
        history.replace('/');
    }

    useEffect(()=>{
        let mounted = true;
        if(mounted){
            fetchData();
        }

        return () => mounted = false;
    },[])

    function fetchData(){
        api.get(`por/${props.porId}/invalidForms`)
        .then(async (res) => {
            const report = res.data.formList;
            setDataSource(report);
        },err=>{
            console.log(err.data)
            setDataSource(null);
        })
    }

    useEffect(()=>{
        let e = document.getElementsByClassName("e-tooltip-wrap e-popup e-lib e-control e-popup-open");
        for(let i = 0; i < e.length; i++ ){
            e[i].style.display = "none";
        }

    },[])

    const formLinkTemplate = (row) => {
        return (
            <img style={{cursor:"pointer"}} src={LinkIcon} alt="" 
            onClick={(e)=>{
                e.stopPropagation();
                window.open(row.formLink, "_blank", "noopener,noreferrer");
            }} />
        );
    }

    const itpReferenceTemplate = (row) => {
        const parts = row.itpReference.split('#####');
        return parts[0];
    };

    const itpItemNoTemplate = (row) => {
        const parts = row.itpReference.split('#####');
        return (parts.length > 1 ? parts[1] : row.itpItemNo);
    };

    const resultTemplate = (row) => {
        switch (row.inspectionResult) {
            case "Endorsed":
                return <span className="text-secondary">{row.inspectionResult}</span>
            case "Cancelled":
                return <span className="text-primary">{row.inspectionResult}</span>
            case "Failed":
                return <span className="text-danger">{row.inspectionResult}</span>
            case "Undetermined":
                return <span className="text-info">{row.inspectionResult}</span>
            default:
                return <span></span>
        }
    }

    const rowSelected = () => {
        const selectedrecords = dt.current.getSelectedRecords();
        if (selectedrecords.length > 0) {
            let obj = selectedrecords[0]
            // console.log(obj)
            setRowInfo(obj)
            setDetailClicked(true);
        }
    }

    const toggleItpReferenceVisibility = () => {
        if (dt.current) {
            if (itpReferenceVisibility) {
                dt.current.hideColumns('ITP Reference', 'headerText');
                setItpReferenceVisibility(false);
                setItpReferenceVisibilityButtonLabel('Show ITP Reference');
            } else {
                dt.current.showColumns('ITP Reference', 'headerText');
                setItpReferenceVisibility(true);
                setItpReferenceVisibilityButtonLabel('Hide ITP Reference');
            }
        }
    };
      
    return (props?
        <div className="content">
            
                
            <div className="page-content">
            <div className="row">
                    <div className="col-sm-auto ">
                        <button type="button" 
                        className="btn btn-dark p-2 pb-3 pt-3 mr-2" 
                        onClick={()=>{
                            history.goBack();
                        }}
                        id="iconButton"><img className="p-0" src={CloseIcon} alt="" /></button>
                    </div>
                    <div className="col">
                        <div className="row">
                            <h5 className=" font-weight-bold">No. of Invalid Forms</h5>
                        </div>
                        <div className="row" >
                            <div className=""><span className="font-weight-bold text-dark">Report Month</span></div>
                            <div className="col-1">{props.reportMonth}</div>
                            <div className=""><span className="font-weight-bold text-dark">Project</span></div>
                            <div className="col-1">{props.project}</div>
                            <div className=""><span className="font-weight-bold text-dark">Contract</span></div>
                            <div className="col-1">{props.contract}</div>
                            <div className=""><span className="font-weight-bold text-dark">Location</span></div>
                            <div className="col-1">{props.location}</div>
                            <div className=""><span className="font-weight-bold text-dark">Discipline</span></div>
                            <div className="col-1">{props.discipline}</div>
                            <div className=""><span className="font-weight-bold text-dark">Uploaded By</span></div>
                            <div className="col-1">{props.createdBy}</div>
                        </div>
                    </div>
                </div>
            

            <hr />

                <div className="table-content p-3">
                    <ButtonComponent className="btn btn-dark float-right" onClick={toggleItpReferenceVisibility}>{itpReferenceVisibilityButtonLabel}</ButtonComponent>
                    <GridComponent
                    dataSource={dataSource}
                    height='60vh'
                    rowSelected={rowSelected}
                    allowTextWrap={true}
                    allowPaging={true}
                    allowSorting={true}
                    ref={dt}
                    // pagerTemplate={pagerTemplate}
                    // created={()=>gridRef.current.setGridPager(pagerTemplate)}
                    pageSettings={{pageSize: 50, pageCount: 5}}
                    //created={fetchKeyTerms}
                    >
                        <ColumnsDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='formLink' headerText='Link to iSuper' template={formLinkTemplate}></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='project' headerText='Project'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="110px" field='contract' headerText='Contract'></ColumnDirective>
                            {/*<ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='cfd' headerText='CFD'></ColumnDirective>*/}
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='riscNo' headerText='RISC No'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" template={itpReferenceTemplate} field='itpReference' headerText='ITP Reference'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" template={itpItemNoTemplate} field='itpItemNo' headerText='ITP Item No'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="110px" field='discipline' headerText='Discipline'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="110px" field='location' headerText='Location'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="110px" field='inspectionDate' headerText='Inspection Date'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="110px" template={resultTemplate} field='inspectionResult' headerText='Inspection Result'></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Page]}/>
                        
                    </GridComponent>
                </div>
                {
                    detailClicked && <DetailDialog 
                    visibility={detailClicked}
                    setVisibility={setDetailClicked}
                    row={rowInfo}
                    />
                }

            </div>


        </div>
        :null
    );

};