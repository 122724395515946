import React, { useRef, useState, useEffect } from "react";
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import api from "../../lib/API";
import { useLocation, useParams, useHistory } from "react-router-dom"
import LinkIcon from "../../images/Link-icon.svg";
import EditIcon from "../../images/Edit-icon.svg";

import DetailDialog from "../JobDetails/Dialog/DetailDialog";
import PredictedInspectionResutlEditDialog from "../JobDetails/Dialog/PredictedInspectionResutlEditDialog";
import LocalStorageService from "../../lib/LocalStorageService";
import PendingEdit from "./Dialog/PendingEdit";
import Reject from "./Dialog/Reject";
import KeyTermFormList from "./KeyTermFormList";

export default function Pending (props) {
    const gridRef = useRef(null);
    const permission = LocalStorageService.getPermissions();
    const location = useLocation()
    const history = useHistory()
    const [params, setParams] = useState(1);
    const [dataSource, setDataSource] = useState(null);
    const [totalRecord, setTotalRecord] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [pageCount, setPageCount] = useState(5);

    const dt = useRef(null);
    const [detailClicked, setDetailClicked] = useState(false);
    const [rowInfo, setRowInfo] = useState({});
    const [showEdit, setShowEdit] = useState(false);
    const [showReject, setShowReject] = useState(false);
    const [keyTermFormRow, setKeyTermFormRow] = useState(null);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            api.get(`keyTermDiscovery/pending`)
                .then(async (res) => {
                    const phraseList = res.data.phraseList;
                    setDataSource(expandPhraseList(phraseList));
                }, err => {
                    console.log(err.data)
                    setDataSource(null);
                });
        }

        return () => mounted = false;
    }, [params, props.updatePending])

    const expandPhraseList = (phraseList) => {
        let expandedPhraseList = [];
        phraseList.forEach(item => {
            let compact = (item.keyTermList.length === 1 && item.phrase === item.keyTermList[0].phrase);
            if (compact) {
                let newItem = {...item};
                newItem.main = true;
                newItem.compact = true;
                newItem.index = 0;
                expandedPhraseList.push(newItem);
            } else {
                let newItem = {...item};
                newItem.main = true;
                newItem.compact = false;
                newItem.index = null;
                expandedPhraseList.push(newItem);
                for (let i = 0; i < item.keyTermList.length; i++) {
                    newItem = {...item};
                    newItem.main = false;
                    newItem.compact = false;
                    newItem.index = i;
                    expandedPhraseList.push(newItem);
                }
            }
        });
        console.log(expandedPhraseList);
        return expandedPhraseList;
    };

    const fetchUpdatePending = () => {
        api.get(`keyTermDiscovery/pending`)
            .then(async (res) => {
                const phraseList = res.data.phraseList;
                setDataSource(expandPhraseList(phraseList));
            }, err => {
                console.log(err.data)
                setDataSource(null);
            });
    }

    const pagerTemplate = () => {
        return (
            <PagerComponent
                totalRecordsCount={totalRecord}
                pageSize={pageSize}
                pageCount={pageCount}
                enableQueryString={true}
                enablePersistence={true}
                click={() =>
                    setParams(window.location.href.slice(window.location.href.search('page')))}></PagerComponent>
        )
    }

    const editTemplate = (row) => {
        return (
            (permission?.search("UPDATE_JOB") !== -1 && row.main && row.status === "Pending")
            ?
            <img style={{cursor:"pointer"}} src={EditIcon} alt="" 
                onClick={(e)=>{
                    e.stopPropagation();
                    setRowInfo(row);
                    setShowEdit(true);
                }}
            />
            :
            null
        );
    }

    const phraseTemplate = (row) => {
        return (
            <span 
                style={{ cursor: row.main ? "pointer" : "default" }}
                onClick={(e)=>{
                if (row.main){
                    e.preventDefault();
                    setKeyTermFormRow(row);
                    props.setIsShowingKeyTermFormList(true);
                }
            }}>
                {row.main ? row.phrase : row.keyTermList[row.index].phrase}
            </span>
        );
    }

    const disciplineTemplate = (row) => {
        return (
            row.index === null
            ?
            null
            :
            row.keyTermList[row.index].discipline
        );
    }

    const keyTermTemplate = (row) => {
        return (
            row.index === null
            ?
            null
            :
            <span className="keytermcolumn">
                {row.keyTermList[row.index].newKeyTerm ? "Other" : null}
                <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield">
                    {row.keyTermList[row.index].keyTerm}
                </span>
            </span>
        );
    }

    const proposedTimeTemplate = (row) => {
        return (
            row.main
            ?
            row.createdDate ? row.createdDate : "-"
            :
            null
        );
    }

    const lastModifiedReasonTemplate = (row) => {
        return (
            row.index === null
            ?
            null
            :
            row.keyTermList[row.index].reason ?? "-"
        );
    }

    const lastModifiedByTemplate = (row) => {
        return (
            row.main
            ?
            row.modifiedBy ? row.modifiedBy : "-"
            :
            null
        );
    }

    const lastModifiedTimeTemplate = (row) => {
        return (
            row.main
            ?
            row.modifiedDate ? row.modifiedDate : "-"
            :
            null
        );
    }

    const actionTemplate = (row) => {
        return (
            (permission?.search("UPDATE_JOB") === -1 || !row.main) ? null : row.status === "Pending" ? (
            <span style={{display:"flex", justifyContent: "space-between"}}>
                <button type="button" className="btn btn-success"
                    onClick={async (e) =>{
                        e.stopPropagation();
                        await api.post(`keyTermDiscovery/${row.keyTermDiscoveryId}/approved`, {reason: "AI Suggestion"})
                        .then(res => {
                            fetchUpdatePending();
                        }).catch(error => {
                            console.log(JSON.stringify(error.response.data));
                        });
                    }}>
                    Approve
                </button>
                <button type="button" className="btn btn-danger"
                    onClick={(e) =>{
                        e.stopPropagation();
                        setRowInfo(row);
                        setShowReject(true);
                    }}>
                    Reject
                </button>
            </span>) : row.status === "Approved" ? (
            <span style={{display:"flex", justifyContent: "space-between", alignItems: "center"}}>
            <p style={{ color: "#38AB3C", fontWeight: "bold", margin: 0, padding: 12}}>Approved</p>
                <button type="button" className="btn cancel"
                    onClick={async (e) =>{
                        e.stopPropagation();
                        await api.post(`keyTermDiscovery/${row.keyTermDiscoveryId}/reopen`, {reason: ""})
                        .then(res => {
                            fetchUpdatePending();
                        }).catch(error => {
                            console.log(JSON.stringify(error.response.data));
                        });
                    }}>
                    Reopen
                </button>
            </span>
            ) : (
            <span style={{display:"flex", justifyContent: "space-between", alignItems: "center"}}>
                <p style={{ color: "#D22B2B", fontWeight: "bold", margin: 0, padding: 12}}>Rejected</p>
                <button type="button" className="btn cancel"
                    onClick={async (e) =>{
                        e.stopPropagation();
                        await api.post(`keyTermDiscovery/${row.keyTermDiscoveryId}/reopen`, {reason: ""})
                        .then(res => {
                            fetchUpdatePending();
                        }).catch(error => {
                            console.log(JSON.stringify(error.response.data));
                        });
                    }}>
                    Reopen
                </button>
            </span>
            )
        );
    }

    const queryCellInfoRow = (args) => {
        if (!args.data.main) {
            args.cell.style.backgroundColor = "#f7f7f7";
        }
    }

    useEffect(()=>{
        if (!props.isShowingKeyTermFormList){
            const queryParams = new URLSearchParams(window.location.search);
            const page = queryParams.get("page");
            if (page) {
                gridRef.current.pageSettings = {
                    ...gridRef.current.pageSettings,
                    currentPage: Number(page),
                };
            }
        }
    },[props.isShowingKeyTermFormList]);

    return (
        <>
            {
                !props.isShowingKeyTermFormList && dataSource && <div className='control-pane'>
                    <div className='control-section' >
                        <span className="float-right">
                            <button type="button" className="btn btn-success mb-2" style={{ display: (permission?.search("UPDATE_JOB") === -1) && "none" }}
                                onClick={async (e) =>{
                                    e.stopPropagation();
                                    await api.post(`keyTermDiscovery/approveAll`)
                                    .then(res => {
                                        fetchUpdatePending();
                                    }).catch(error => {
                                        console.log(JSON.stringify(error.response.data));
                                    });
                                }}>
                                Approve All
                            </button>
                        </span>
                        <GridComponent
                            ref={gridRef}
                            height="calc(100vh - 340px)"
                            //rowSelected={rowSelected}
                            allowPaging={true}
                            allowTextWrap={true}
                            allowSorting={false}
                            //ref={dt}
                            //queryString={true}
                            dataSource={dataSource}
                            queryCellInfo={queryCellInfoRow}
                            pageSettings={{ enableQueryString: true, pageSize: 50, pageCount: 5 }} >
                            <ColumnsDirective>
                                <ColumnDirective headerTextAlign="left" textAlign="left" width="20px" template={editTemplate}></ColumnDirective>
                                <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='phrase' headerText='Phrase' template={phraseTemplate}></ColumnDirective>
                                <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='discipline' headerText='Discipline' template={disciplineTemplate}></ColumnDirective>
                                <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='keyTerm' headerText='Key Term' template={keyTermTemplate}></ColumnDirective>
                                <ColumnDirective headerTextAlign="left" textAlign="left" width="80px" field='createdDate' headerText='Proposed Time' template={proposedTimeTemplate}></ColumnDirective>
                                <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='reason' headerText='Last Modified Reason' template={lastModifiedReasonTemplate}></ColumnDirective>
                                <ColumnDirective headerTextAlign="left" textAlign="left" width="80px" field='modifiedBy' headerText='Last Modified By' template={lastModifiedByTemplate}></ColumnDirective>
                                <ColumnDirective headerTextAlign="left" textAlign="left" width="80px" field='modifiedDate' headerText='Last Modified Time' template={lastModifiedTimeTemplate}></ColumnDirective>
                                <ColumnDirective headerTextAlign="left" textAlign="left" width="80px" template={actionTemplate}></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Page]} />

                        </GridComponent>
                    </div>
                </div>
            }
            {
                detailClicked && <DetailDialog
                    visibility={detailClicked}
                    setVisibility={setDetailClicked}
                    row={rowInfo}
                    jobID={props.jobId}
                />
            }
            {
                showEdit && <PendingEdit
                    setVisibility={setShowEdit}
                    row={rowInfo}
                    fetchUpdate={fetchUpdatePending}
                />
            }
            {
                showReject && <Reject
                    setVisibility={setShowReject}
                    row={rowInfo}
                    fetchUpdate={fetchUpdatePending}
                />
            }
            {
                props.isShowingKeyTermFormList && <KeyTermFormList
                    row={keyTermFormRow}
                    setVisibility={props.setIsShowingKeyTermFormList}
                />
            }
        </>
    );
}