import React, {useRef, useState, useEffect} from "react";
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import api from "../../lib/API";
import {useLocation, useParams, useHistory } from "react-router-dom"
import LinkIcon from "../../images/Link-icon.svg";
import EditIcon from "../../images/Edit-icon.svg";
import CloseIcon from "../../images/Close-icon.svg";

import FormDialog from "../JobDetails/Dialog/FormDialog";
import PredictedInspectionResutlEditDialog from "../JobDetails/Dialog/PredictedInspectionResutlEditDialog";

export default function KeyTermFormList(props){
    const history = useHistory()
    const [params, setParams] = useState(1);
    const [fullDataSource, setFullDataSource] = useState(null);
    const [dataSource, setDataSource] = useState(null);
    const [totalRecord, setTotalRecord] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [pageCount, setPageCount] = useState(5);

    const dt = useRef(null);
    const [detailClicked, setDetailClicked] = useState(false);
    const [rowInfo, setRowInfo] = useState({});
    const [showEdit, setShowEdit] = useState(false);
    const [formFilter, setFormFilter] = useState('');

    const [itpReferenceVisibilityButtonLabel, setItpReferenceVisibilityButtonLabel] = useState('Hide ITP Reference');
    const [itpReferenceVisibility, setItpReferenceVisibility] = useState(true);

    useEffect(() => {
    let mounted = true;
        if (mounted) {
            api.get(`keyTermDiscovery/related/${props.row.keyTermDiscoveryId}`)
                .then(async (res) => {
                    const report = res.data.report;
                    setDataSource(report);
                }, err => {
                    console.log(err.data);
                    setDataSource(null);
                });
        }
        return () => mounted = false;
    }, [params]);

    const fetchUpdate = () => {
        api.get(`keyTermDiscovery/related/${props.row.keyTermDiscoveryId}`)
                .then(async (res) => {
                    const report = res.data.report;
                    setDataSource(report);
                },err=>{
            console.log(err.data)
            setDataSource(null);
        })
    }
   
    const formLinkTemplate = (row) => {
        return (
            <img style={{cursor:"pointer"}} src={LinkIcon} alt="" 
            onClick={(e)=>{
                e.stopPropagation();
                window.open(row.formLink, "_blank", "noopener,noreferrer");
            }} />
        );
    }

    const itpReferenceTemplate = (row) => {
        const parts = row.itpReference.split('#####');
        return parts[0];
    };

    const itpItemNoTemplate = (row) => {
        const parts = row.itpReference.split('#####');
        return (parts.length > 1 ? parts[1] : row.itpItemNo);
    };

    const resultTemplate = (row) => {
        switch (row.predictedInspectionResult) {
            case "Endorsed":
                return (
                    <span className="text-secondary" style={{whiteSpace:"nowrap"}}>
                        {row.isIncorporated ? <span className="green-dot"></span> : (row.isAdjusted ? <span className="red-dot"></span> : <span className="empty-dot"></span>)}
                        {row.predictedInspectionResult}
                        <img className="keytermedit" style={{cursor:"pointer"}} src={EditIcon} alt="" 
                            onClick={(e)=>{
                                e.stopPropagation();
                                setRowInfo(row);
                                setShowEdit(true);
                            }} />
                    </span>
                );
            case "Cancelled":
                return (
                    <span className="text-primary" style={{whiteSpace:"nowrap"}}>
                        {row.isIncorporated ? <span className="green-dot"></span> : (row.isAdjusted ? <span className="red-dot"></span> : <span className="empty-dot"></span>)}
                        {row.predictedInspectionResult}
                        <img className="keytermedit" style={{cursor:"pointer"}} src={EditIcon} alt="" 
                            onClick={(e)=>{
                                e.stopPropagation();
                                setRowInfo(row);
                                setShowEdit(true);
                            }} />
                    </span>
                );
            case "Failed":
                return (
                    <span className="text-danger" style={{whiteSpace:"nowrap"}}>
                        {row.isIncorporated ? <span className="green-dot"></span> : (row.isAdjusted ? <span className="red-dot"></span> : <span className="empty-dot"></span>)}
                        {row.predictedInspectionResult}
                        <img className="keytermedit" style={{cursor:"pointer"}} src={EditIcon} alt="" 
                            onClick={(e)=>{
                                console.log("hi");
                                e.stopPropagation();
                                setRowInfo(row);
                                setShowEdit(true);
                            }} />
                    </span>
                );
            case "Undetermined":
                return (
                    <span className="text-info" style={{whiteSpace:"nowrap"}}>
                        {row.isIncorporated ? <span className="green-dot"></span> : (row.isAdjusted ? <span className="red-dot"></span> : null)}
                        {row.predictedInspectionResult}
                        <img className="keytermedit" style={{cursor:"pointer"}} src={EditIcon} alt="" 
                            onClick={(e)=>{
                                e.stopPropagation();
                                setRowInfo(row);
                                setShowEdit(true);
                            }} />
                    </span>
                );
            default:
                return <span></span>
        }
    };

    const closeEditDialog = () => {
        setShowEdit(false);
    };

    const pagerTemplate = () => {
        return (
            <PagerComponent 
                totalRecordsCount={totalRecord} 
                pageSize={pageSize} 
                pageCount={pageCount} 
                params={params}
                enableQueryString={true}
                enablePersistence={true}
                click={()=>
                    setParams(window.location.href.slice(window.location.href.search('page')))}></PagerComponent>
        )
    }
    
    
    const rowSelected = () => {
        console.log("hello");
        const selectedrecords = dt.current.getSelectedRecords();
        if (selectedrecords.length > 0) {
            let obj = selectedrecords[0]
            console.log(obj)
            setRowInfo(obj)
            setDetailClicked(true);
        }
    }

    const toggleItpReferenceVisibility = () => {
        if (dt.current) {
            if (itpReferenceVisibility) {
                dt.current.hideColumns('ITP Reference', 'headerText');
                setItpReferenceVisibility(false);
                setItpReferenceVisibilityButtonLabel('Show ITP Reference');
            } else {
                dt.current.showColumns('ITP Reference', 'headerText');
                setItpReferenceVisibility(true);
                setItpReferenceVisibilityButtonLabel('Hide ITP Reference');
            }
        }
    };

    return (
        <>
            <div className='control-pane'>
                <div className='control-section' >
                <table style={{width: "100%"}}>
                    <tr>
                        <td>
                            <div className="float-left" style={{fontSize: "15px"}}>
                                <button
                                    type="button" 
                                    className="btn btn-dark"
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        props.setVisibility(false);
                                    }}><img className="p-0" src={CloseIcon} alt="" /></button>
                                <span className="font-weight-bold" style={{"padding-left": "10px"}}>Phrase</span>
                                <span style={{"padding": "10px"}}>{
                                    props.row.main
                                    ?
                                    props.row.phrase
                                    :
                                    props.row.keyTermList[props.row.index].phrase
                                }</span>
                                <span className="font-weight-bold" style={{"padding-left": "10px"}}>Discipline</span>
                                <span style={{"padding": "10px"}}>{
                                    props.row.index === null
                                    ?
                                    null
                                    :
                                    props.row.keyTermList[props.row.index].discipline
                                }</span>
                                <span className="font-weight-bold" style={{"padding-left": "10px"}}>Key Term</span>
                                <span style={{"padding": "10px"}}>
                                    {props.row.keyTermList[props.row.index]?.newKeyTerm ? "Other" : null}
                                    <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield">{props.row.keyTermList[props.row.index]?.keyTerm}</span>
                                </span>
                            </div>
                        </td> 
                    </tr>
                    <hr />
                    <tr>
                        <td style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span className="font-weight-bold" style={{fontSize: "18px", alignSelf: "center"}}>Risc Form List</span>
                                <span style={{ paddingLeft: "20px" }}>(Show the latest 10 relevant RISC forms according to Inspection Date)</span>
                            </div>
                            <div className="float-right" style={{whiteSpace: "nowrap"}}>
                                <span><ButtonComponent className="btn btn-dark" onClick={toggleItpReferenceVisibility}>{itpReferenceVisibilityButtonLabel}</ButtonComponent></span>
                            </div>
                        </td>
                    </tr>
                </table>

                <GridComponent 
                height= "calc(100vh - 410px)"
                rowSelected={rowSelected}
                allowPaging={true}
                allowTextWrap={true}
                allowSorting={true}
                ref={dt}
                //queryString={true}
                dataSource={dataSource}
                pageSettings={{enableQueryString:true, pageSize:50, pageCount: 5}} >
                    <ColumnsDirective>
                        <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='formLink' headerText='Link to iSuper' template={formLinkTemplate}></ColumnDirective>
                        <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='project' headerText='Project'></ColumnDirective>
                        <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='contract' headerText='Contract'></ColumnDirective>
                        {/*<ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='cfd' headerText='CFD'></ColumnDirective>*/}
                        <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='riscNo' headerText='RISC No'></ColumnDirective>
                        <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" template={itpReferenceTemplate} field='itpReference' headerText='ITP Reference'></ColumnDirective>
                        <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" template={itpItemNoTemplate} field='itpItemNo' headerText='ITP Item No'></ColumnDirective>
                        <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='discipline' headerText='Discipline'></ColumnDirective>
                        <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='location' headerText='Location'></ColumnDirective>
                        <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='endorser' headerText='Endorser'></ColumnDirective>
                        <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='role' headerText='Role'></ColumnDirective>
                        <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='inspectionDate' headerText='Inspection Date' format='yMd'></ColumnDirective>
                        <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='inspectionResult' headerText='Inspection Result'></ColumnDirective>
                        <ColumnDirective headerTextAlign="left" textAlign="left" width="160px" template={resultTemplate} field='predictedInspectionResult' headerText='Predicted Inspection Result'></ColumnDirective>
                        <ColumnDirective headerTextAlign="left" textAlign="left" width="160px" field='reasonsForPrediction' headerText='Reason For Prediction'></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Page]}/>
                    
                </GridComponent>
                </div>
            </div>
            {
                detailClicked && <FormDialog 
                setVisibility={setDetailClicked}
                row={rowInfo}
                jobID={props.jobId}
                fetchUpdate={fetchUpdate}
                />
            }
        </>
    );
}