import React, {useEffect, useRef, useState} from "react";
import Uploader from "./Uploader";
import "./styles.css";
import moment from 'moment';

import DetailLogo from "../../images/Detail-icon.svg";

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useLocation, useHistory } from "react-router-dom";
import { DateTimePickerComponent  } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import DeleteDialog from "./Dialog/DeleteDialog";
import WarningDialog from "./Dialog/WarningDialog";
import ErrorDialog from "./Dialog/ErrorDialog";
import LoadingComponent from "../Loading/LoadingComponent";

import JobQueueTable from "./JobQueueTable"
import { MAX_CONCURRENT_UPLOAD, MAX_UPLOAD_SIZE_FORMATTED } from "../../limit";
import api from "../../lib/API";
import localStorageService from "../../lib/LocalStorageService";

export default function TextAnalytics(){
    
    const [uploadFileName, setUploadFileName] = useState('')
    const [uploading, setUploading] = useState(false);

    const [ErrorTriggered, setError] = useState(false);
    const [WarningClicked, setWarning] = useState(false);
    const [DeleteClicked, setDelete] = useState(false);
    const [rowInfo, setRowInfo] = useState({});

    const [errorMessage, setErrorMessage] = useState('');
   
    const [data, setData] = useState({
        "source": [],
        "uploadedBy": [],
        "totalCount": 0,
        "pendingCount": 0,
        "inQueueCount": 0,
        "inProgressCount": 0,
        "completedCount": 0
    });
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const [uploadedTimeFrom, setUploadedTimeFrom] = useState('');
    const [uploadedTimeTo, setUploadedTimeTo] = useState('');
    const [uploadedBy, setUploadedBy] = useState(parseInt(queryParams.get("uploadedBy"), 10) || 0);
        
    const [oversizeArr,setOversizeArr] = useState([]);
    const [errorArr, setErrorArr] = useState([]);
    const [isNumOfFilesExceeded, setIsNumOfFilesExceeded] = useState(false);
    const [deleteError, setDeleteError] = useState('');

    const gridRef = useRef(null);
   
    const AI_VERSION = (process.env.REACT_APP_AI_VERSION  || '1.0');

    function fetchJobs(){
        const queryParams = new URLSearchParams(location.search);
        queryParams.set("page", 1);
        queryParams.set("uploadedTimeFrom", uploadedTimeFrom);
        queryParams.set("uploadedTimeTo", uploadedTimeTo);
        queryParams.set("uploadedBy", uploadedBy);
        Promise.resolve(history.replace({ search: queryParams.toString() }))
        .then(() => {
            if (gridRef.current) {
                gridRef.current.refresh(uploadedTimeFrom, uploadedTimeTo, uploadedBy);
            }
        })
        .catch(error => {
            console.error("Error updating URL:", error);
        });
    }
     
    const detailTemplate = () => {
        return (
            <div className="detail">
                Supported file type: CSV<br />
                Maximum file size: {MAX_UPLOAD_SIZE_FORMATTED}<br />
                Maximum no. of files: {MAX_CONCURRENT_UPLOAD}
            </div>
        )
    };

    const downloadCSV = (blob, fileName="file.csv" ) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        link.remove();
    };

    const permission = localStorageService.getPermissions();

    return (
        <>
            <div className="content pb-0">
                <div className="heading mb-3">
                    <h4 className="page-title">Text Analytics</h4>
                </div>
                <div className="top">
                    

                    <Uploader 
                    enabled={permission?.search("CREATE_JOB") !== -1}
                    setUploading={setUploading}
                    setError = {setError}
                    setUploadFileName={setUploadFileName}
                    fetchJobs={fetchJobs}
                    setErrorMessage={setErrorMessage}
                    setErrorArr={setErrorArr}
                    setOversizeArr={setOversizeArr}
                    setIsNumOfFilesExceeded={setIsNumOfFilesExceeded} />
                    
                    <TooltipComponent position="TopCenter" content={detailTemplate} target="#target">
                        <img src={DetailLogo} className="ml-3" alt="" id="target" />
                    </TooltipComponent>

                    <span className="separator"></span>
                    <div className="ai-version">
                        <h5 style={{fontSize:"18px", fontWeight:"600"}}><span className="text-dark">AI v{AI_VERSION}</span></h5>                        
                    </div>
                
                    <span className="separator"></span>
                    <div className="status-count ml-3">
                        <div className="total pr-4">
                        <h5 style={{fontSize:"18px", fontWeight:"600"}}><span className="text-dark">Total<span className="ml-1 pl-2 pr-2 badge badge-pill badge-dark">{data.totalCount}</span></span></h5>
                        </div>
                        <div className="pending">
                        <h5 style={{fontSize:"18px", fontWeight:"600"}}><span className="text-secondary">Pending<span className="ml-1 pl-2 pr-2 badge badge-pill badge-secondary">{data.pendingCount} </span></span></h5>
                        </div>
                        <div className="inQueue">
                        <h5 style={{fontSize:"18px", fontWeight:"600"}}><span className="text-primary">In Queue<span className="ml-1 pl-2 pr-2 badge badge-pill badge-primary">{data.inQueueCount} </span></span></h5>
                        </div>
                        <div className="inprogress">
                        <h5 style={{fontSize:"18px", fontWeight:"600"}}><span className="text-warning">In Progress<span className="ml-1 pl-2 pr-2 badge badge-pill badge-warning text-light">{data.inProgressCount} </span></span></h5>
                        </div>
                        <div className="completed">
                        <h5 style={{fontSize:"18px", fontWeight:"600"}}><span className="text-success">Completed<span className="ml-1 pl-2 pr-2 badge badge-pill badge-success">{data.completedCount} </span></span></h5>
                        </div>
                    </div>
                </div>
                <div className="top">
                    <span className="mr-3">
                        <DateTimePickerComponent id="uploadedTimeFrom" placeholder="From Uploaded Time" width="250px" height="50px"
                        format="yyyy-MM-dd HH:mm" timeFormat="HH:mm"
                        value={uploadedTimeFrom ? new Date(uploadedTimeFrom) : null}
                        change={(e)=>{
                            if (e.value) {
                                setUploadedTimeFrom(moment(e.value).format('YYYY-MM-DD HH:mm:ss'));
                            } else {
                                setUploadedTimeFrom('');
                            }
                        }}
                        cleared={()=>{
                            setUploadedTimeFrom('');
                        }}
                        />
                    </span>
                    <span className="mr-3">
                        to
                    </span>
                    <span className="mr-3">
                        <DateTimePickerComponent id="uploadedTimeTo" placeholder="To Uploaded Time" width="250px" height="50px"
                        format="yyyy-MM-dd HH:mm" timeFormat="HH:mm"
                        value={uploadedTimeTo ? new Date(uploadedTimeTo) : null}
                        change={(e)=>{
                            if (e.value) {
                                setUploadedTimeTo(moment(e.value).format('YYYY-MM-DD HH:mm:ss'));
                            } else {
                                setUploadedTimeTo('');
                            }
                        }}
                        cleared={()=>{
                            setUploadedTimeTo('');
                        }}
                        />
                    </span>
                    <span className="mr-3">
                        <DropDownListComponent id="uploadedBy" placeholder="Uploaded By" width="200px" height="50px"
                        fields={{text:"displayName", value:"userId"}}
                        dataSource={data.uploadedBy}
                        value={uploadedBy}
                        change={(e)=>{
                            setUploadedBy(e.itemData.userId);
                        }}
                        />
                    </span>
                    <span>
                        <button
                        className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                        onClick={()=>{
                            if (uploadedTimeFrom && uploadedTimeTo && uploadedTimeFrom > uploadedTimeTo) {
                                alert("'From Uploaded Time' should be earlier than 'To Uploaded Time'.");
                            } else {
                                fetchJobs();
                            }
                        }}
                        >Filter</button>
                    </span>
                    <span className="separator"></span>
                    <span>
                        <button
                        className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                        onClick={()=>{
                            if (gridRef.current) {
                                gridRef.current.batchDownload();
                            }
                        }}
                        >Download</button>
                    </span>

                    <div className="float-right" style={{whiteSpace: "nowrap"}}>
                        <span style={{paddingRight: "5px"}}></span>
                        <span>
                            <a
                            style={{cursor:"pointer", textDecoration:"underline"}}
                            onClick={async () => {
                                await api.get(`/jobs/template`,{responseType: 'blob'})
                                .then(res =>{
                                if (res.status === 200 && res.data) {
                                    let fileName = "template";
                                    let disposition = res.headers["content-disposition"];
                                    let idx = disposition.indexOf("filename=");
                                    if (idx > 0) {
                                        fileName = disposition.substring(idx+"filename=".length);
                                        fileName = fileName.replaceAll('"', '')
                                    }
                                    downloadCSV(res.data, fileName); 
                                }else{
                                    console.log("download failed");
                                }
                                }).catch(error => {
                                    console.log(JSON.stringify(error.response.data));
                                });
                            }}
                            >Template for Upload</a>
                        </span>
                    </div>
                </div>
                </div>
                <JobQueueTable
                    ref={gridRef}
                    setData={setData}
                    setFilters={{
                        setUploadedTimeFrom: setUploadedTimeFrom,
                        setUploadedTimeTo: setUploadedTimeTo,
                        setUploadedBy: setUploadedBy
                    }}
                    setError = {setError}
                    setUploadFileName={setUploadFileName}
                    setWarning={setWarning}
                    setRowInfo={setRowInfo}
                    setDelete={setDelete}
                />
            {
                DeleteClicked && <DeleteDialog
                visibility={DeleteClicked}
                setError={setError}
                setDeleteError={setDeleteError}
                setVisibility={setDelete}
                jobId={rowInfo.jobId}
                fetchJobs={fetchJobs} />
            }
            {
                WarningClicked && <WarningDialog 
                visibility={WarningClicked}
                setVisibility={setWarning}
                row={rowInfo}
                dataSource={data.source}
                fetchJobs={fetchJobs} 
                />
            }
            {
                ErrorTriggered && <ErrorDialog 
                visibility = {ErrorTriggered}
                setVisibility = {setError}
                deleteError={deleteError}
                setDeleteError={setDeleteError}
                fileName={uploadFileName}
                errorMessage={errorMessage}
                fetchJobs={fetchJobs}
                errorArr={errorArr}
                oversizeArr={oversizeArr}
                setErrorArr={setErrorArr}
                setOversizeArr={setOversizeArr}
                isNumOfFilesExceeded={isNumOfFilesExceeded}
                />
            }
            {uploading ? <LoadingComponent /> : null}
        </>
    );

}
